var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pd-x-3 themeVote"},[_c('header',{staticClass:"pd-t-7"},[_c('el-row',{staticClass:"border-bottom",attrs:{"type":"flex"}},[_c('el-col',{staticClass:"is-flex ai-center",attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticStyle:{"width":"88px"},attrs:{"src":require("@/assets/vote-logo.png"),"alt":""}})])],1),_c('el-col',{staticClass:"text-right",attrs:{"span":12}},[_c('el-row',{attrs:{"type":"flex","justify":"end"}},[(_vm.room != null)?_c('div',{staticClass:"is-flex ai-center mg-r-3"},[_c('span',{staticClass:"cr-pointer",on:{"click":function($event){_vm.dialogVisible = true}}},[_c('v-qr',{attrs:{"text":_vm.url + 'join-room/' + _vm.room.code,"qrcodeColor":"#000000ff","backgroundColor":"#ffffffff","size":60,"ratio":1,"margin":8,"level":"M"}})],1),_c('div',{staticClass:"text-left mg-l-7"},[_c('h4',{staticClass:"mg-y-7"},[_c('span',{staticClass:"text-grey"},[_vm._v("ชื่อห้อง:")]),_vm._v(" "+_vm._s(_vm.room.name)+" ")]),_c('h4',{staticClass:"mg-y-7"},[_c('span',{staticClass:"text-grey"},[_vm._v("Room ID:")]),_vm._v(" "+_vm._s(_vm.room.code)+" ")])]),(_vm.user != null && _vm.step != null)?_c('el-dropdown',{staticClass:"mg-l-3",attrs:{"trigger":"click"}},[_c('span',{staticClass:"cr-pointer flex-display ai-center"},[_c('i',{staticClass:"fas fa-ellipsis-v fa-lg"})]),_c('el-dropdown-menu',{staticClass:"pd-y-7",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',[_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.cancelRoom}},[_vm._v("ยกเลิกห้อง")])],1)])],1):_vm._e()],1):_vm._e(),(_vm.user != null)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"cr-pointer flex-display ai-center"},[_c('div',{staticClass:"box-image mg-y-6"},[_c('avatar',{attrs:{"username":_vm.user.name,"rounded":false,"src":`${
                        _vm.user.profileImg != 'undefined' && _vm.user.profileImg
                          ? _vm.imgUrl + _vm.user.profileImg
                          : ''
                      }`,"size":40}})],1)]),_c('el-dropdown-menu',{staticStyle:{"width":"300px"},attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-row',[_c('el-col',{staticClass:"is-flex flex-fill js-center pd-b-6",attrs:{"span":24}},[_c('div',[_c('div',{staticClass:"flex-display js-center pd-l-4 pd-r-7"},[_c('div',{staticClass:"box-image"},[_c('avatar',{attrs:{"username":_vm.user.name,"rounded":false,"src":`${
                                _vm.user.profileImg != 'undefined' &&
                                _vm.user.profileImg
                                  ? _vm.imgUrl + _vm.user.profileImg
                                  : ''
                              }`,"size":80}})],1)])]),_c('div',{staticClass:"pd-l-6"},[_c('h4',{staticClass:"mg-b-7 mg-t-0"},[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.surname)+" ")]),_c('p',{staticClass:"text-grey mg-y-7"},[_vm._v(_vm._s(_vm.user.email))])])])],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"pd-4 pd-b-6"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":"/admin-edit-profile"}},[_vm._v(" แก้ไขข้อมูลส่วนตัว ")])],1),_c('div',{staticClass:"pd-x-4 pd-b-4"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":"/change-password"}},[_vm._v(" แก้ไขรหัสผ่าน ")])],1),(_vm.user.role == 50 || _vm.user.role == 60)?_c('div',{staticClass:"pd-t-4 pd-x-4 border-top"},[_c('router-link',{staticClass:"text-grey",attrs:{"to":_vm.user.role == 50 ? '/log-system' : '/dashboard'}},[_vm._v(" เมนูผู้ดูแล ")])],1):_vm._e(),_c('div',{staticClass:"pd-4 pd-y-6 text-grey cr-pointer",on:{"click":function($event){return _vm.signout()}}},[_vm._v(" ออกจากระบบ ")])])],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"pd-y-7"},[_vm._t("default")],2),_c('el-dialog',{staticClass:"text-center",attrs:{"visible":_vm.dialogVisible,"width":"40%","top":"5vh"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.room != null)?_c('div',[_c('h3',{staticClass:"mg-y-0"},[_vm._v("เข้าร่วม")]),_c('h2',{staticClass:"mg-y-6 color-primary"},[_c('span',[_vm._v(_vm._s(_vm.url + "join-room/" + _vm.room.code))])]),_c('el-divider',[_c('h3',{staticClass:"mg-y-0"},[_vm._v("หรือ")])]),_c('span',[_c('div',{staticClass:"is-flex ai-center js-center"},[_c('v-qr',{attrs:{"text":_vm.url + 'join-room/' + _vm.room.code,"qrcodeColor":"#000000ff","backgroundColor":"#ffffffff","size":400,"ratio":1,"level":"M"}})],1)])],1):_vm._e()])],1),_c('Cookie',{attrs:{"pattern":2}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }